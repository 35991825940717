import { atom, selector } from 'recoil';
import {
  IDetailWaypointResponseData,
  IFiltersWaypoint,
  IWaypoint,
  IWaypointPostion,
} from './interfaces';
import Config from '../../../../config';
import { bounds, zoom } from '../OnlineMapState';
import { SuperclusterWaypointWorker } from '../CreateWorkers';

export const waypointsAtom = atom<IWaypoint[]>({
  key: 'waypoints',
  default: [],
});
export const detailWaypointAtom = atom<IDetailWaypointResponseData | null>({
  key: 'detailWaypoint',
  default: null,
});
export const activeFilterAtom = atom<IFiltersWaypoint | null>({
  key: 'activeFilter',
  default: null,
});

export const waypointsLoadedAtom = atom<boolean>({
  key: 'waypointsLoaded',
  default: false,
});

export const isWaypointsOpenAtom = atom<boolean>({
  key: 'isWaypointsOpen',
  default: false,
});

export const selectedWaypointIdAtom = atom<number | null>({
  key: 'selectedWaypointId',
  default: null,
});

export const computedAreaAtom = atom<number>({
  key: 'computedArea',
  default: 0,
});

export const waypointDataAtom = atom<IDetailWaypointResponseData | null>({
  key: 'waypointData',
  default: null,
});

export const showWaypointLoaderAtom = atom<boolean>({
  key: 'showWaypointLoader',
  default: false,
});

export const filteredWaypointsStringAtom = atom<string>({
  key: 'filteredWaypointsString',
  default: '',
});

export const filteredWaypointsAtom = atom<IWaypoint[]>({
  key: 'filteredWaypoints',
  default: [],
});

export const onHoverWaypointIdAtom = atom<number | null>({
  key: 'onHoverWaypointId',
  default: null,
});

export const removedWaypointIdAtom = atom<number | null>({
  key: 'removedWaypointId',
  default: null,
});

export const canEditWaypointAtom = atom<boolean>({
  key: 'canEditWaypoint',
  default: false,
});

export const canDrawWaypointAtom = atom<boolean>({
  key: 'canDrawWaypoint',
  default: false,
});

export const isCopyModeWpAtom = atom<boolean>({
  key: 'isCopyModeWp',
  default: false,
});

export const waypointsDataAtom = selector<IWaypoint[]>({
  key: 'waypointsData',
  get: ({ get }) => {
    let waypointsDataWorker = get(waypointsAtom);
    const zoomData = get(zoom);
    const boundsData = get(bounds);
    const filteredWaypointsStringWorker = get(filteredWaypointsStringAtom);
    const filteredWaypointsWorker = get(filteredWaypointsAtom);
    if (filteredWaypointsStringWorker.length > 0) {
      waypointsDataWorker = filteredWaypointsWorker;
    }

    SuperclusterWaypointWorker.postMessage({
      waypointsData: waypointsDataWorker,
      bounds: boundsData,
      zoom: zoomData,
      minPoints: Config.onlineMap.superCluster.minPoints,
    });
    return waypointsDataWorker;
  },
});

export const waypointIdAtom = atom<number | null>({
  key: 'waypointId',
  default: null,
});

export const coordinatesAtom = atom<IWaypointPostion[] | null>({
  key: 'coordinates',
  default: null,
});

export const centerWaypointAtom = atom<IWaypointPostion | null>({
  key: 'centerWaypoint',
  default: null,
});

export const rawCoordinatesAtom = atom<google.maps.LatLng[] | null>({
  key: 'rawCoordinates',
  default: null,
});

export const isEditModeAtom = atom<boolean>({
  key: 'isEditMode',
  default: false,
});
export const isDrawWaypointAtom = atom<boolean>({
  key: 'isDrawWaypoint',
  default: false,
});

export const isMinimalizedWpAtom = atom<boolean>({
  key: 'isMinimalizedWp',
  default: false,
});
