import { atom, selector, selectorFamily } from 'recoil';
import { IOnlinePanelData } from '../OnlineMapNew/PanelsLayer/interfaces';
import { IAnnouncement } from '../RidePlayer/interfaces';
import { IGraphResponseData } from '../Graphs/GraphService';
import { TVehicleStatus } from '../Vehicles/interfaces';

export interface IOnlinePanelDefaultTemplateChange {
  customerId: number | null;
  template: IOnlinePanelData | null;
  vehicleId: number[] | null;
}

export interface IOnlinePanelUserTemplateChange {
  customerId: number | null;
  template: IOnlinePanelData | null;
  userId: number | null;
  vehicleId: number[] | null;
}

export interface IActiveWaypointItem {
  id: number;
  name: string;
  type: string;
  userId: number;
}

export interface IVehicleAlarmItem {
  id: number;
  name: string;
  users: number[];
}

export interface IWidgetGraph {
  vehicleId: number;
  timestamp?: string;
  data?: IGraphResponseData[];
  name?: string;
  rideId?: string;
}

export interface IVehicleOnlinePanelElectric {
  charging: boolean | null;
  state: boolean | null;
  plugged: boolean | null;
  percent: number | null;
  remaining: number | null;
  batteryRange: number | null;
}

export interface IVehicleOnlinePanelState {
  adBlueLevel: number | null;
  vehicleId: number;
  // alarms: IVehicleAlarmItem[];
  altitude: number | null;
  battery: number | null;
  // customerId: number;
  // doors: {
  //   fl: boolean | null;
  //   fr: boolean | null;
  //   hd: boolean | null;
  //   rl: boolean | null;
  //   rr: boolean | null;
  //   tr: boolean | null;
  // } | null;
  engineTemperature: number | null;
  engineState: null | boolean;
  fuelLevelLiters: number | null;
  fuelLevelPercentage: number | null;
  rpm: number | null;
  satellites: number | null;
  speed: number | null;
  throttle: number | null;
  ecoDriveScore: number | null;
  timestamp: string; // Set timestamp from position or any other timestamp from response. Used internally for ecodrive violations
  // distanceCumulative: number | null;
  // activeWaypoints: IActiveWaypointItem[];
  // odometerCan: number | null;
  onCruise: boolean | null;
  onCng: boolean | null;
  onLpg: boolean | null;
  remoteLock: boolean | null;
  // isPrivate: boolean;
  latitude: number;
  longitude: number;
  announcements: IAnnouncement[] | [];
  address: string | null;
  webasto: null | boolean;
  pto: null | boolean;
  ignition: boolean;
  // drivingMode: TDrivingMode;
  electric: IVehicleOnlinePanelElectric | null;
  code: string;
  mileage: null | {
    timestamp: string;
    value: number;
  };
  seatbelts: {
    driver: false;
    frontPassenger: false;
    leftPassenger: false;
    rightPassenger: false;
    centrePassenger: false;
  } | null;
  coolantLevelLow: null | boolean;
  oilLevelLow: null | boolean;
  checkEngine: null | boolean;
  esp: boolean | null;
}

export const ANNOUNCEMENT_TYPE_EVENT = 'event';
export const ANNOUNCEMENT_TYPE_WAYPOINT = 'waypoint';
export const ANNOUNCEMENT_TYPE_ALARM = 'alarm';
export const FRONT_LEFT_DOOR_OPENED = 'front_left_door_opened';
export const FRONT_RIGHT_DOOR_OPENED = 'front_right_door_opened';
export const REAR_LEFT_DOOR_OPENED = 'rear_left_door_opened';
export const REAR_RIGHT_DOOR_OPENED = 'rear_right_door_opened';
export const HOOD_OPENED = 'hood_opened';
export const TRUNK_OPENED = 'trunk_opened';
export const ALL_DOORS_OPENED = 'all_doors_opened';
export const WARNING_LIGHT_ON = 'warning_light_on';
export const WARNING_LIGHT_OFF = 'warning_light_off';
export const WARNING_SOUND_ON = 'warning_sound_on';
export const WARNING_SOUND_OFF = 'warning_sound_off';
export const ANNOUNCEMENT_TYPE_EVENT_BINARY = 'binary';
export const ANNOUNCEMENT_TYPE_EVENT_DOORS = 'door';
export const ANNOUNCEMENT_TYPE_EVENT_BLUECOINS = 'bluecoin';

export interface IVehicleOnlinePanels {
  [vehicleId: number]: IVehicleOnlinePanelState;
}

// isOnlinePanelOpen contains vehicleId as number or false
export const isOnlinePanelOpen = atom<number | false>({
  key: 'isOnlinePanelOpen',
  default: false,
});

export const onlinePanel = atom<IVehicleOnlinePanels>({
  key: 'onlinePanel',
  default: {},
});

export const onlineMapTemplates = atom<IOnlinePanelData[]>({
  key: 'onlineMapTemplates',
  default: [],
});

export const defaultOnlinePanelTemplate = atom<IOnlinePanelDefaultTemplateChange>({
  key: 'defaultOnlinePanelTemplate',
  default: { customerId: null, template: null, vehicleId: null },
});

export const userOnlinePanelTemplate = atom<IOnlinePanelUserTemplateChange>({
  key: 'userOnlinePanelTemplate',
  default: { customerId: null, template: null, userId: null, vehicleId: null },
});

// Get online panel data for specified vehicle
export const onlinePanelForVehicle = selectorFamily<
  IVehicleOnlinePanelState | null,
  number | false
>({
  key: 'onlinePanelForVehicle',
  get:
    (vehicleId: number | false) =>
    ({ get }) => {
      if (!vehicleId) {
        return null;
      }
      const onlinePanelData = get(onlinePanel);
      const onlinePanelDataForVehicle = onlinePanelData[vehicleId];
      if (onlinePanelDataForVehicle) {
        return onlinePanelDataForVehicle;
      }
      return null;
    },
});

// Get online panel data for selected vehicles (based on isOnlinePanelOpen)
export const onlinePanelForSelectedVehicle = selector<IVehicleOnlinePanelState | null>({
  key: 'onlinePanelForVehicle',
  get: ({ get }) => {
    const vehicleId = get(isOnlinePanelOpen);
    if (!vehicleId) {
      return null;
    }
    const onlinePanelData = get(onlinePanel);
    const onlinePanelDataForVehicle = onlinePanelData[vehicleId];
    if (onlinePanelDataForVehicle) {
      return onlinePanelDataForVehicle;
    }
    return null;
  },
});

export const binaryEvents = selectorFamily<IAnnouncement[], number | false>({
  key: 'binaryEvents',
  get:
    (vehicleId: number | false) =>
    ({ get }) => {
      if (!vehicleId) {
        return [];
      }
      const onlinePanelData = get(onlinePanel);

      const onlinePanelDataForVehicle = onlinePanelData[vehicleId];
      console.log('onlinePanelDataForVehicle', onlinePanelDataForVehicle);
      if (!onlinePanelDataForVehicle) {
        return [];
      }
      return onlinePanelDataForVehicle.announcements.filter(
        (data: IAnnouncement) =>
          data.type === ANNOUNCEMENT_TYPE_EVENT && data.eventType === ANNOUNCEMENT_TYPE_EVENT_BINARY
        // (data.title === WARNING_LIGHT_OFF ||
        //   data.title === WARNING_LIGHT_ON ||
        //   data.title === WARNING_SOUND_OFF ||
        //   data.title === WARNING_SOUND_ON)
      );
    },
});

// Get online panel data events for doors by vehicle id
export const doorsEventsForVehicle = selectorFamily<IAnnouncement[], number | false>({
  key: 'doorsEventsForVehicle',
  get:
    (vehicleId: number | false) =>
    ({ get }) => {
      if (!vehicleId) {
        return [];
      }
      const onlinePanelData = get(onlinePanel);
      const onlinePanelDataForVehicle = onlinePanelData[vehicleId];
      if (!onlinePanelDataForVehicle) {
        return [];
      }
      return onlinePanelDataForVehicle.announcements.filter(
        (data: IAnnouncement) =>
          data.type === ANNOUNCEMENT_TYPE_EVENT &&
          (data.title === FRONT_LEFT_DOOR_OPENED ||
            data.title === FRONT_RIGHT_DOOR_OPENED ||
            data.title === REAR_LEFT_DOOR_OPENED ||
            data.title === REAR_RIGHT_DOOR_OPENED ||
            data.title === HOOD_OPENED ||
            data.title === TRUNK_OPENED ||
            data.title === ALL_DOORS_OPENED)
      );
    },
});

// Get online panel data events for doors by selected vehicle (based on isOnlinePanelOpen)
export const doorsEventsForSelectedVehicle = selector<IAnnouncement[]>({
  key: 'doorsEventsForVehicle',
  get: ({ get }) => {
    const vehicleId = get(isOnlinePanelOpen);
    if (!vehicleId) {
      return [];
    }
    const onlinePanelData = get(onlinePanel);
    const onlinePanelDataForVehicle = onlinePanelData[vehicleId];
    if (!onlinePanelDataForVehicle) {
      return [];
    }
    return onlinePanelDataForVehicle.announcements.filter(
      (data: IAnnouncement) =>
        data.type === ANNOUNCEMENT_TYPE_EVENT &&
        (data.title === FRONT_LEFT_DOOR_OPENED ||
          data.title === FRONT_RIGHT_DOOR_OPENED ||
          data.title === REAR_LEFT_DOOR_OPENED ||
          data.title === REAR_RIGHT_DOOR_OPENED ||
          data.title === HOOD_OPENED ||
          data.title === TRUNK_OPENED ||
          data.title === ALL_DOORS_OPENED)
    );
  },
});

export const isLoadingExtraDataForWidgets = atom<boolean>({
  key: 'isLoadingExtraDataForWidgets',
  default: false,
});
export const lastStatus = atom<TVehicleStatus>({
  key: 'lastStatus',
  default: 'STOP',
});
export const onlinePanelData = atom<IOnlinePanelData | null>({
  key: 'onlinePanelData',
  default: null,
});
